// Entry point for the build script in your package.json

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Grid overlay
import gridOverlay from "./lib/gridOverlay";
gridOverlay();

// Navigation
import navigation from "./features/navigation";
navigation();

import "./features/expandableBlocks";
