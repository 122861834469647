import { uniqueId } from "lodash";

import readyHandler from "../lib/readyHandler";

readyHandler.ready(function () {
  const blocks = document.querySelectorAll(".expandable-block");

  blocks.forEach((block) => {
    const toggleElement = block.children[0];

    if (
      ["H1", "H2", "H3", "H4", "H5", "H6"].indexOf(toggleElement.tagName) == -1
    ) {
      return;
    }

    const content = document.createElement("div");
    const id = uniqueId("expandable-");
    content.classList.add("expandable-content");
    content.id = id;

    Array.from(block.childNodes).forEach((child) => {
      if (child !== toggleElement) {
        content.appendChild(block.removeChild(child));
      }
    });

    block.appendChild(content);

    toggleElement.setAttribute("aria-controls", id);
    toggleElement.setAttribute("aria-expanded", "false");

    toggleElement.classList.add("expandable-toggle");
    toggleElement.addEventListener("click", (evt) => {
      evt.preventDefault();
      block.classList.toggle("expandable-expanded");
      if (block.classList.contains("expandable-expanded")) {
        toggleElement.setAttribute("aria-expanded", "true");
      } else {
        toggleElement.setAttribute("aria-expanded", "false");
      }
    });
  });
});
